module.exports = {
    types: [
        // this line is used by starport scaffolding
        { type: "estimator", fields: ["estimator", "estimation", "itemid", "interested", "comment", "flag"] },
       // { type: "estimator/delete", fields: ["creator", "itemid"] },

       // { type: "estimator/set", fields: ["creator","itemid", "interested"] },
       // { type: "estimator/flag", fields: ["creator","flag", "itemid", ] },
        //{ type: "item/reveal", fields: ["creator", "itemid",] },
       // { type: "item/transferable", fields: ["seller", "transferbool", "itemid",] },
        //{ type: "item/transfer", fields: ["seller", "transferbool", "itemid",] },
        //{ type: "item/shipping", fields: ["seller", "tracking", "itemid",] },
        { type: "item", fields: ["seller", "title", "description", "shipping_cost", "local_pickup", "estimation_count", "estimation_price", "buyer", "status", "transferable", "best_estimator", "lowestestimator", "highestestimator", "comments", "tags", "condition", "shippingregion", "deposit_amount", "note", "discount", "creator", "is_token"]},
       // { type: "item/set", fields: ["seller", "shipping_cost", "local_pickup", "shippingregion",]},
       // { type: "item/delete", fields: ["seller", "id"]},
        { type: "buyer", fields: ["buyer", "deposit", "itemid"] },
    ],
}