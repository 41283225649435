<template>
  <v-app>
    <div>
      <router-view :key="$route.path" />
    </div>

    <v-app-bar
      :color="$vuetify.theme.dark ? 'grey darken-4' : 'primary lighten-3'"
      app
      dense   clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-col cols="2" md="2"><v-row>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
         <router-link
              to="/"
              class="pa-0"
              style="text-decoration: none; color: inherit"
              >
              <v-row v-bind="attrs" v-on="on"
                ><img
                  class="ma-4 mt-5"
                  src="img/brand/icon.png"
                  width="32"
                  height="32"
              /></v-row>
            </router-link>
          </template>
          <span>Watches</span>
        </v-tooltip><v-btn plain class="d-none d-md-flex " @click="search = !search" icon>
        <v-icon  v-if="!search">mdi-magnify</v-icon><v-icon v-else>mdi-close</v-icon>
      </v-btn>
</v-row>
      </v-col>

      <v-col cols="8" md="8" class="pa-0">
        <v-tabs v-if="!search && $vuetify.breakpoint.mdAndUp" 
          show-arrows
          hide-slider
          fixed-tabs
          :background-color="
            $vuetify.theme.dark ? 'grey darken-4' : 'primary lighten-3'
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab to="/"
                ><v-icon v-bind="attrs" v-on="on" large>
                  mdi-shopping
                </v-icon></v-tab
              >
            </template>
            <span>Shop</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab to="/sell">
                <v-icon v-bind="attrs" v-on="on" large>
                  mdi-plus-box
                </v-icon></v-tab
              >
            </template>
            <span>Sell</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab to="/estimate">
                <v-icon v-bind="attrs" v-on="on" large>
                  mdi-checkbox-marked </v-icon
                ><v-badge
                  color="red"
                  :content="messagesToEstimate"
                  :value="messagesToEstimate"
              /></v-tab>
            </template>
            <span>Estimate</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab to="/account">
                <v-icon v-bind="attrs" v-on="on" large> mdi-account-box </v-icon
                ><v-badge
                  color="red"
                  :content="messagesAccount"
                  :value="messagesAccount"
                />
              </v-tab>
            </template>
            <span>Account</span>
          </v-tooltip>
        </v-tabs><v-row class="mt-3" v-else> <v-col cols="4" class="d-none d-md-flex"> <v-btn rounded @click="search = false" plain><v-icon left small> mdi-arrow-left</v-icon> Menu</v-btn></v-col><v-col cols="12" md="8"> <v-combobox full-width rounded background-color="primary lighten-2" flat v-model="input" clearable @keydown.enter="searchItem" @click:clear="input = ''" placeholder="Search..."  v-on:input="searchItem" :items="taglist"
 dense  solo hide-no-data
  prepend-inner-icon="mdi-magnify"
></v-combobox> </v-col></v-row></v-col
      ><v-col class="justify-center d-none d-md-flex" cols="2" md="2">
      <template
          ><span> 
            <v-btn rounded elevation="1" small color="primary" to="/messages">
              <v-icon>mdi-message-reply </v-icon>
            </v-btn>
          </span>
        </template>
      </v-col>
    </v-app-bar>

    <v-navigation-drawer dense clipped
      v-model="drawer"
      :color="$vuetify.theme.dark ? 'grey darken-4' : 'primary lighten-3'"
      :fixed="$vuetify.breakpoint.smAndUp"
      :temporary="$vuetify.breakpoint.xsOnly"
       absolute app
    >
      <v-list nav dense shaped>
        <v-alert
          dense
          v-if="!this.$store.state.user && this.$store.state.account.address"
          type="warning"
          dismissible
          class="caption"
        >
          Confirm sign in by clicking the link sent to your Google account's
          email on this device.
        </v-alert>
        <div class="text-center">
          <img class="pa-4" src="img/brand/icon.png" width="77" />
        </div>
        <wallet v-if="this.$store.state.account.address" />

        <v-list-item-group
          active-class="primary-lighten-4--text text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-icon
              ><v-icon> mdi-shopping </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Buy</v-list-item-title>
          </v-list-item>

          <v-list-item to="/sell">
            <v-list-item-icon
              ><v-icon> mdi-plus-box </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sell</v-list-item-title>
          </v-list-item>

          <v-list-item to="/estimate">
            <v-list-item-icon
              ><v-icon> mdi-checkbox-marked </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Estimate</v-list-item-title>
          </v-list-item>

          <v-list-item to="/account">
            <v-list-item-icon
              ><v-icon> mdi-account-box </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>

          <v-list-item to="/messages">
            <v-list-item-icon
              ><v-icon>mdi-message-reply </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Messages</v-list-item-title>
          </v-list-item>
          <v-list-item to="/explore">
            <v-list-item-title>Explore</v-list-item-title>
          </v-list-item>
          <div
            @click="welcome = !welcome"
            v-if="!this.$store.state.account.address"
          >
            <v-list-item @click="welcome = !welcome" inactive>
              <v-list-item-title>Get Started</v-list-item-title>
            </v-list-item>
          </div>
          <v-list-item
            target="_blank"
            href="https://www.trustlesshub.com"
          >
            <v-list-item-title>About TRST</v-list-item-title>
          </v-list-item>
          <v-list-item to="/FAQ">
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item>
          <v-list-item inactive id="mode-switcher" @click="toggledarkmode">
            <v-list-item-title>Theme</v-list-item-title
            ><v-icon
              :color="
                $vuetify.theme.dark ? 'primary-lighten-3' : 'primary lighten-1'
              "
            >
              {{
                $vuetify.theme.dark ? "mdi-weather-night" : "mdi-weather-sunny"
              }}
            </v-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <welcome
      v-if="
        (!this.$store.state.account.address && welcome) ||
        (!this.$store.state.user && welcome)
      "
    />
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-footer class="text-center caption" color="primary lighten-3" padless>
      <v-col
        >{{ new Date().getFullYear() }} —
        <strong>© Trustless Hub</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>





<script>
export default {
  data() {
    return {
      //dismiss: false,
      //dialog: true,
      fab: false,
      drawer: false,
      welcome: true,
      search: false,
      input: "",
    };
  },
  created() {
    this.$store.dispatch("init");

    //this.$store.dispatch("setBuyItemList");

    //this.$store.dispatch("initBuyItemList");
  },
  /* messages() {
      return this.$store.getters.getSellerItemList.length || 0;
    },*/

  methods: {
    toggledarkmode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
     searchItem() {
       console.log(this.input)
     if (this.input != null) {
    this.$router.push("/search="+ this.input)
     }
     },
       searchFirst() {
       if(this.taglist[0])
    this.$router.push("/search="+ this.taglist[0])
     }
  },

  computed: {
    messagesAccount() {
      return this.$store.getters.getSellerActionList.length || 0;
    },
    messagesToEstimate() {
      return this.$store.getters.getToEstimateList.length || 0;
    },
     taglist() {
       this.$store.dispatch("setSortedTagList");
      let list = [
          "Rolex",
           "Omega",    
          "Submariner",
          "Seamaster",
            "Daytona",
             "Orient",
            "TAG Heuer",
               "Seiko",
       
          "Cartier",
      
      ]
        let tags = this.$store.getters.getTagList;

      return list.concat( tags)
  
    },
  },

  mounted() {
    //this.messages = this.$store.getters.getSellerItemList.length;
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },

};
</script>
