import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import IconCoin from '../components/IconCoin.vue' 

Vue.use(Vuetify)

const opts = { iconfont: 'md', 
    theme: { 
        themes: {
            light: {
              primary: '#718892',
              secondary: '#afc7fc',
              accent: '#08E4F4',
              error: '#b71c1c',
            },
            dark: {
                primary: '#000000',
                secondary: '#050c04',
                accent: '#08E4F4',
                error: '#b71c1c',
              },
            
     },
    
   },  icons: {
    values: {
      custom: { // name of our custom icon
        component: IconCoin, // our custom component
      },
    },
   },
  };

export default new Vuetify(opts)